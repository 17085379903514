<template>
  <v-card outlined>
    <v-card-title>
      <v-spacer></v-spacer>
      <base-field-input
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></base-field-input>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="orders"
      :loading="isLoading"
      loader-height="1"
      :items-per-page="50"
      sort-by="attributes.createdAt"
      :search="search"
      :footer-props="{
        itemsPerPageOptions: [25, 50, 75, 100, -1]
      }"
    >
      <!-- 
      Status 
    -->
      <template v-slot:[`item.attributes.status`]="{ item }">
        <base-status-chip x-small :status="item.attributes.status" />
      </template>
      <!-- 
      Specie
     -->
      <template
        v-slot:[`item.attributes.customAttributes.specie.text`]="{ item }"
      >
        {{
          item.attributes.customAttributes.specie &&
            $t(`species.${item.attributes.customAttributes.specie.text}`)
        }}
      </template>
      <!-- 
      Forest Manager 
      -->
      <template v-slot:[`item.attributes.forestManagers`]="{ item }">
        <div v-for="group in item.attributes.forestManagers" :key="group.id">
          {{ group }}
        </div>
      </template>
      <!-- 
    Name
    -->
      <template v-slot:[`item.attributes.customAttributes.name`]="{ item }">
        <div class="info--text subtitle-2 font-weight-medium mb-1 ">
          <v-icon
            size="small"
            left
            :color="
              item.attributes.clientOrganizationId
                ? 'success'
                : 'grey lighten-4'
            "
            >{{ 'mdi-swap-vertical-bold' }}</v-icon
          >
          {{ item.attributes.customAttributes.name }}
        </div>
        <span
          v-if="item.attributes.createdAt"
          class="text--secondary caption"
          >{{ item.attributes.createdAt | moment('MMM Do YYYY') }}</span
        >
      </template>

      <!-- 
      Supplier 
    -->
      <template v-slot:[`item.attributes.supplierCompanyName`]="{ item }">
        <div class="subtitle-2">{{ item.attributes.supplierCompanyName }}</div>
        <div>{{ item.attributes.supplierContactName }}</div>
      </template>

      <!-- 
      Certificates 
    -->
      <template
        v-slot:[`item.attributes.customAttributes.certificates`]="{ item }"
      >
        <div
          v-for="(certificate, index) in item.attributes.customAttributes
            .certificates || []"
          :key="index"
        >
          <div class="caption">{{ certificate.category }}</div>
          <div>{{ certificate.code }}</div>
        </div>
      </template>

      <!-- 
      ETA 
    -->
      <template
        v-slot:[`item.attributes.estimatedUnloadDateTo`]="{
          item
        }"
      >
        <span v-if="item.attributes.estimatedUnloadDateTo" class="body-2">{{
          item.attributes.estimatedUnloadDateTo | moment('MMM Do YYYY')
        }}</span>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <BaseTableActions
          module-name="purchaseOrder"
          :item="item"
          :to-show="{
            name:
              item.attributes.subCategory === 'sale'
                ? 'sale-purchase-order-show'
                : 'purchase-order-show',
            params: { orderId: item.id }
          }"
          :to-edit="{
            name: 'purchase-order-edit',
            params: {
              orderId: item.id
            }
          }"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { ORGANIZATION_ID } from '@/common/config.js'
import BaseTableActions from '@/components/buttons/BaseTableActions'
export default {
  name: 'PurchaseOrderIndexTable',
  components: {
    BaseTableActions
  },
  data() {
    return {
      search: '',
      isLoading: true
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      orders: 'purchaseOrder/orders'
    }),
    headers() {
      return this.createTableHeaders()
    }
  },
  async created() {
    this.isLoading = true

    await this.$store.dispatch('purchaseOrder/getMappedOrders', {
      organizationId: ORGANIZATION_ID(),
      subCategory: 'purchase',
      partyCategory: 'Provider',
      loadCategory: 'Destination'
    })
    this.isLoading = false
  },
  methods: {
    createTableHeaders() {
      return [
        {
          text: this.$t('purchase.shortName'),
          align: 'start',
          sortable: false,
          value: 'attributes.customAttributes.name'
        },
        {
          text: this.$tc('user.supplier', 1),
          value: 'attributes.supplierCompanyName'
        },
        {
          text: this.$t('item.specie'),
          value: 'attributes.customAttributes.specie.text'
        },
        {
          text: this.$tc('item.certificate', 2),
          value: 'attributes.customAttributes.certificates',
          sortable: false
        },
        {
          text: this.$tc('user.forestManager', 1),
          value: 'attributes.forestManagers',
          align: 'start'
        },
        {
          text: this.$t('load.eta'),
          value: 'attributes.estimatedUnloadDateTo'
        },
        {
          text: this.$t('orderStatus.name'),
          value: 'attributes.status'
        },
        {
          text: '',
          value: 'id',
          sortable: false,
          align: 'end'
        }
      ]
    }
  }
}
</script>
