<template>
  <base-index-layout :title="$tc('purchase.name', 2)">
    <template #new-button>
      <base-new-button class="mx-4" :to="{ path: 'new' }" append />
    </template>
    <PurchaseOrderIndexTable />
  </base-index-layout>
</template>

<script>
import PurchaseOrderIndexTable from './components/PurchaseOrderIndexTable'

export default {
  components: {
    PurchaseOrderIndexTable
  }
}
</script>

<style lang="scss" scoped></style>
