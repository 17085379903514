var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-spacer'),_c('base-field-input',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.orders,"loading":_vm.isLoading,"loader-height":"1","items-per-page":50,"sort-by":"attributes.createdAt","search":_vm.search,"footer-props":{
      itemsPerPageOptions: [25, 50, 75, 100, -1]
    }},scopedSlots:_vm._u([{key:`item.attributes.status`,fn:function({ item }){return [_c('base-status-chip',{attrs:{"x-small":"","status":item.attributes.status}})]}},{key:`item.attributes.customAttributes.specie.text`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.attributes.customAttributes.specie && _vm.$t(`species.${item.attributes.customAttributes.specie.text}`))+" ")]}},{key:`item.attributes.forestManagers`,fn:function({ item }){return _vm._l((item.attributes.forestManagers),function(group){return _c('div',{key:group.id},[_vm._v(" "+_vm._s(group)+" ")])})}},{key:`item.attributes.customAttributes.name`,fn:function({ item }){return [_c('div',{staticClass:"info--text subtitle-2 font-weight-medium mb-1"},[_c('v-icon',{attrs:{"size":"small","left":"","color":item.attributes.clientOrganizationId
              ? 'success'
              : 'grey lighten-4'}},[_vm._v(_vm._s('mdi-swap-vertical-bold'))]),_vm._v(" "+_vm._s(item.attributes.customAttributes.name)+" ")],1),(item.attributes.createdAt)?_c('span',{staticClass:"text--secondary caption"},[_vm._v(_vm._s(_vm._f("moment")(item.attributes.createdAt,'MMM Do YYYY')))]):_vm._e()]}},{key:`item.attributes.supplierCompanyName`,fn:function({ item }){return [_c('div',{staticClass:"subtitle-2"},[_vm._v(_vm._s(item.attributes.supplierCompanyName))]),_c('div',[_vm._v(_vm._s(item.attributes.supplierContactName))])]}},{key:`item.attributes.customAttributes.certificates`,fn:function({ item }){return _vm._l((item.attributes.customAttributes
          .certificates || []),function(certificate,index){return _c('div',{key:index},[_c('div',{staticClass:"caption"},[_vm._v(_vm._s(certificate.category))]),_c('div',[_vm._v(_vm._s(certificate.code))])])})}},{key:`item.attributes.estimatedUnloadDateTo`,fn:function({
        item
      }){return [(item.attributes.estimatedUnloadDateTo)?_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(_vm._f("moment")(item.attributes.estimatedUnloadDateTo,'MMM Do YYYY')))]):_vm._e()]}},{key:`item.id`,fn:function({ item }){return [_c('BaseTableActions',{attrs:{"module-name":"purchaseOrder","item":item,"to-show":{
          name:
            item.attributes.subCategory === 'sale'
              ? 'sale-purchase-order-show'
              : 'purchase-order-show',
          params: { orderId: item.id }
        },"to-edit":{
          name: 'purchase-order-edit',
          params: {
            orderId: item.id
          }
        }}})]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }